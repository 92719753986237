import {useSelector} from "react-redux";
import {store} from "../../storage";

const AbuDhabiPage = () => {

    const {locale} = useSelector(store  => store.app)

    return(
        locale === 'en'
            ? <>
                <h1>Why Abu Dhabi?</h1>
                <p>Investing in real estate in Abu Dhabi offers strong returns, with high rental yields, property value
                    appreciation, and a tax-efficient environment, including no property, income, or capital gains
                    taxes.</p>

                <p>Unlike Dubai, Abu Dhabi offers a more relaxed, less crowded environment while maintaining a high standard
                    of luxury and modern infrastructure. The city is known for its focus on sustainability, culture, and
                    green spaces, making it ideal for those seeking a balanced lifestyle.</p>

                <p>Abu Dhabi is also one of the safest cities globally, with a stable political climate. Under Sheikh
                    Mohamed bin Zayed Al Nahyan’s leadership, initiatives like the “Golden Visa” and long-term investments
                    in clean energy and innovation enhance its appeal for investors seeking stability and growth.</p>
            </>
            : <>
                <h1>Почему стоит инвестировать в недвижимость в Абу-Даби?</h1>
                <p>Инвестиции в недвижимость в Абу-Даби предлагают стабильные доходы, высокие арендные ставки, рост
                    стоимости объектов и налоговые преимущества, включая отсутствие налогов на недвижимость, доход и прирост
                    капитала.</p>

                <p> В отличие от Дубая, Абу-Даби предлагает более спокойную, менее загруженную атмосферу, при этом сохранен
                    высокий уровень роскоши и современная инфраструктура. Город известен своим вниманием к устойчивому
                    развитию, культуре и зеленым пространствам, что делает его привлекательным для тех, кто ищет
                    сбалансированный образ жизни.</p>

                <p>Абу-Даби также считается одним из самых безопасных городов в мире с устойчивым политическим климатом. Под
                    руководством шейха Мохаммеда бин Заеда Аль Нахаяна реализуются инициативы, такие как «Золотая виза», а
                    также долгосрочные инвестиции в чистую энергию и инновации, что увеличивает его привлекательность для
                    инвесторов, ищущих стабильность и возможности для роста.</p>
            </>
    )
}
export default AbuDhabiPage