import './assets/style.scss'
import {Mousewheel, Navigation, Pagination} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import IndexPage from "../../pages/IndexPage";
import DubaiPage from "../../pages/DubaiPage";
import RasAlKheimahPage from "../../pages/RasAlKheimahPage";
import AbuDhabiPage from "../../pages/AbuDhabiPage";
import Faq from "../../pages/Faq";
import {useState} from "react";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import PageSlide from "./PageSlide";

const PageSwiper = () => {

    const [preview, setX] = useState(1)
    const [swiper, setSwiper] = useState(null);

    return(
        <div className={'page-swiper-container'}>
            <Swiper
                slidesPerView={preview}
                centeredSlides={true}
                navigation={false}
                modules={[Pagination, Navigation, Mousewheel]}
                mousewheel={{
                    enabled: true,
                    forceToAxis: false,
                    sensitivity: 1,
                    releaseOnEdges: true,
                }}
                touchReleaseOnEdges={false}
                freeMode={true}
                onSwiper={(s) => {
                    setSwiper(s);
                }}
                onSlideChange={(s) => {
                    // setSwiperState()
                }}
            >
                <SwiperSlide><PageSlide component={<Faq />} /></SwiperSlide>
                {/*<SwiperSlide><PageSlide component={<IndexPage />} /></SwiperSlide>*/}
                {/*<SwiperSlide><PageSlide component={<DubaiPage />} /></SwiperSlide>*/}
                {/*<SwiperSlide><PageSlide component={<RasAlKheimahPage />} /></SwiperSlide>*/}
                {/*<SwiperSlide><PageSlide component={<AbuDhabiPage />} /></SwiperSlide>*/}

            </Swiper>
        </div>
    )
}

export default PageSwiper