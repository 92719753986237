import './style.scss'
import {useEffect, useState} from "react";

const PopupContainer = ({close, children}) => {

    const [movable, setMovable] = useState(false)
    let closeTimeout

    useEffect(() => {

        const openTimeout = setTimeout(() => {
            setMovable(true)
        }, 100)

        return(() => {
            clearTimeout(openTimeout)
            clearTimeout(closeTimeout)
        })
    }, []);

    const closeHandler = () => {
        setMovable(false)
        closeTimeout = setTimeout(() => {
            close(false)
        }, 300)
    }

    return(
        <div className={'popup-container'}>
            <div className="close" onClick={closeHandler}><i className={'icon-close'} /></div>
            <div className={`movable-container ${movable ? 'active' : 'hidden'}`}>
                {children}
            </div>
        </div>
    )
}

export default PopupContainer