import './style.scss'
import {useEffect, useState} from "react";

const Input = ({label, value, onInput, mode = 'text', error, required}) => {

    const [filled, setFilled] = useState(false)
    const [touched, setTouched] = useState(false)
    const inputHandler = (value) => {
        onInput(value)
    }

    useEffect(() => {
        if(value) {
            setFilled(true)
            setTouched(true)
        } else {
            setFilled(false)
        }
    }, [filled]);

    return(
        <div className={'input-group'}>
            <div className="label">{label}</div>
            <div className="input-block">
                <input type="text" value={value}
                       onChange={(event) => inputHandler(event.currentTarget.value)}
                />
            </div>
        </div>
    )
}

export default Input