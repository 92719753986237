import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {
    addPosition, closeForm, fillTestData,
    removePosition, resetForm,
    setActive,
    setDropdown,
    setErrors,
    setField,
    unsetError
} from "../../storage/FormReducer";
import Selector from "../Selector";
import PhoneInput from "react-phone-number-input"
import 'react-phone-number-input/style.css'
import './style.scss'
import {CoreHttp} from "../../http/axios";
import ButtonLoading from "../ButtonLoading";
import Input from "../Input";
import RadioButtonGroup from "../RadioButtonGroup";

const RequestForm = () => {

    const d = useDispatch()
    const {locale, device} = useSelector(store => store.app)
    const {active, fields, dropdown, errors, from} = useSelector(store => store.form)
    const {name, phone, email, positions} = fields
    const plist = [...useSelector(store => store.content[locale].positions)]
    const [loading, setLoading] = useState(false)
    const [thank, setThank] = useState(false)


    useEffect(() => {
        const bodyLock = document.querySelector('body')
        bodyLock.style.overflow = 'hidden'

        return(() => {
            bodyLock.removeAttribute("style");
        })
    }, [active]);

    plist.sort(function(a, b) {
        return a.title.localeCompare(b.title);
    });

    useEffect(() => {

        if(!thank)
            return

        const tmThank = setTimeout(() => {
            setThank(false)
            d(closeForm())
        }, 6000)

        return(() => {
            clearTimeout(tmThank)
        })
    }, [thank]);

    const sendRequest = async () => {

        setLoading(true)

        try {
            const response = await CoreHttp.post('/lead/add', fields)

            if(response.status === 200) {
                await setThank(true)
                await d(resetForm())
            }
        } catch (e) {
            if(e.response.status === 422) {
                d(setErrors(e.response.data))
            } else if(e.response.status === 400) {
                alert('Token error! Please, reload the page and try once again!')
            } else if(e.response.status === 403) {
                alert('Token forbidden! Please, reload the page and try once again!')
            } else {
                alert('Something went wrong!')
            }

            setLoading(false)
        }
    }

    const resetError = (key) => {
        if(errors[key]) {
            d(unsetError(key))

            if(key !== 'positions') {
                d(setField({field: key, value: ''}))
            }
        }
    }


    return (
        <div className={'request-form-component'}>
            <div className="heading">
                {locale === 'en'
                    ? <>
                        <h2>Leave a Request</h2>
                        <h3>Fill out this short form, and I will get in touch with you</h3>
                    </>
                    : <>
                        <h2>Оставьте Вашу заявку</h2>
                        <h3>Заполните эту короткую форму, и я свяжусь с вами</h3>
                    </>

                }

            </div>

            <div className="section">
                <div className="field-group">
                    <div className="field">
                        <Input
                            label={locale === 'en' ? 'Your Name' : 'Ваше имя'}
                            onInput={value => d(setField({field: 'name', value: value}))}
                            value={fields.name}
                            error={errors.name}
                            required={true}
                        />
                    </div>
                    <div className="field">
                        <Input
                            label={locale === 'en' ? 'Phone Number' : 'Контактный телефон'}
                            onInput={value => d(setField({field: 'phone', value: value}))}
                            value={fields.phone}
                            error={errors.phone}
                            required={true}
                        />
                    </div>
                    <div className="field">
                        <Input
                            label={'E-mail'}
                            onInput={value => d(setField({field: 'email', value: value}))}
                            value={fields.email}
                            error={errors.email}
                            required={true}
                        />
                    </div>
                </div>
            </div>

            <div className="section">
                {locale === 'en'
                    ? <h4>Are you interested in purchasing a property?</h4>
                    : <h4>Вы заинтересованы в приобретении недвижимости?</h4>
                }

                <RadioButtonGroup
                    required={true}
                    error={errors.interested}
                    value={fields.interested}
                    onSelect={value => d(setField({field: 'interested', value: value}))}
                    items={[
                        {label: locale === 'en' ? 'Yes' : 'Да', value: 'Yes'},
                        {label: locale === 'en' ? 'No' : 'Нет', value: 'No'},
                    ]}
                    classnames={'col-2'}
                />
            </div>

            <div className="section">
                {locale === 'en'
                    ? <h4>Are you looking for a ready property or one under construction?</h4>
                    : <h4>Вы ищете готовый объект или строящийся?</h4>
                }

                <RadioButtonGroup
                    required={true}
                    error={errors.readiness}
                    value={fields.readiness}
                    onSelect={value => d(setField({field: 'readiness', value: value}))}
                    items={[
                        {label: locale === 'en' ? 'Ready Property' : 'Готовый', value: 'Ready'},
                        {label: locale === 'en' ? 'Under Construction' : 'Строящийся', value: 'Under Construction'},
                    ]}
                    classnames={'col-2'}
                />
            </div>

            <div className="section">
                {locale === 'en'
                    ? <h4>What type of property are you interested in?</h4>
                    : <h4>Какой тип недвижимости вас интересует?</h4>
                }

                <RadioButtonGroup
                    required={true}
                    error={errors.type}
                    value={fields.type}
                    onSelect={value => d(setField({field: 'type', value: value}))}
                    items={[
                        {label: locale === 'en' ? 'Apartment' : 'Апартаменты', value: 'Apartment'},
                        {label: locale === 'en' ? 'Townhouse' : 'Таунхаус', value: 'Townhouse'},
                        {label: locale === 'en' ? 'Villa' : 'Вилла', value: 'Villa'},
                        {label: locale === 'en' ? 'Mansion' : 'Особняк', value: 'Mansion'},
                    ]}
                    classnames={'col-2'}
                />
            </div>

            <div className="section">
                {locale === 'en'
                    ? <h4>When do you plan to make the purchase?</h4>
                    : <h4>Когда вы планируете совершить покупку?</h4>
                }

                <RadioButtonGroup
                    required={true}
                    error={errors.when}
                    value={fields.when}
                    onSelect={value => d(setField({field: 'when', value: value}))}
                    items={[
                        {label: locale === 'en' ? 'Within 3 months' : 'В течение 3 месяцев', value: 'Within 3 months'},
                        {label: locale === 'en' ? 'Within 6 months' : 'В течение 6 месяцев', value: 'Within 6 months'},
                        {label: locale === 'en' ? 'Within 1 year' : 'В течение 1 года', value: 'Within 1 year'},
                        {
                            label: locale === 'en' ? 'More than 1 year' : 'Более чем через 1 год',
                            value: 'More than 1 year'
                        },
                    ]}
                    classnames={'col-2'}
                />
            </div>

            <div className="section">
                {locale === 'en'
                    ? <h4>What is your budget?</h4>
                    : <h4>Каков ваш бюджет?</h4>
                }

                <RadioButtonGroup
                    required={true}
                    error={errors.budget}
                    value={fields.budget}
                    onSelect={value => d(setField({field: 'budget', value: value}))}
                    items={[
                        {label: locale === 'en' ? 'Up to 300K' : 'До 300K', value: 'Up to 300K'},
                        {label: locale === 'en' ? '300K - 600K' : '300K - 600K', value: '300K - 600K'},
                        {label: locale === 'en' ? '600k - 1M' : '600k - 1M', value: '600k - 1M'},
                        {
                            label: locale === 'en' ? 'More than 1M' : 'Более 1M',
                            value: 'More than 1M'
                        },
                    ]}
                    classnames={'col-2'}
                />
            </div>
        </div>
    )
}
export default RequestForm