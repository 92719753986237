import { createSlice } from '@reduxjs/toolkit'

export const formSlice = createSlice({
    name: 'form',
    initialState: {
        active: false,
        dropdown:false,
        fields: {
            name: '',
            phone: '',
            email: '',
            interested: '',
            positions: []
        },
        errors: {},
    },
    reducers: {
        setActive: (state) => {
            state.active = true
        },
        closeForm: (state) => {
            state.active = false
        },
        setDropdown: (state, {payload}) => {
            state.dropdown = !state.dropdown
        },
        removePosition: (state, {payload}) => {
            state.fields.positions = state.fields.positions.filter((k, index) => index !== payload)
        },
        addPosition: (state, {payload}) => {

            if(state.fields.positions.filter((k, index) => k.title === payload.title).length === 0) {
                state.fields.positions.push({title: payload.title, qty: 1, edit: false})
                state.dropdown = false
            }
        },
        setField: (state, {payload}) => {
            state.fields[payload.field] = payload.value
        },
        setErrors: (state, {payload}) => {
            state.errors = payload
        },
        unsetError: (state, {payload}) => {
            delete state.errors[payload]
        },
        resetForm: (state) => {
            state.fields = {
                name: '',
                phone: '',
                email: '',
                positions: []
            }
            state.errors = {}
        },
        fillTestData: (state) => {
            state.fields = {
                name: 'John Smith',
                phone: '+995591678675',
                email: 'john.s@gmail.com',
                positions: [{title: 'Web Developer'}]
            }
            state.errors = {}
        },
    }
})

export const {
    setActive,
    closeForm,
    setDropdown,
    removePosition,
    addPosition,
    setField,
    setErrors,
    unsetError,
    resetForm,
    fillTestData,
} = formSlice.actions
export default formSlice.reducer
