import {useSelector} from "react-redux";
import {store} from "../../storage";

const RasAlKheimahPage = () => {

    const {locale} = useSelector(store  => store.app)

    return(
        locale === 'en'
            ? <>
                <h1>Why Ras Al Khaimah?</h1>
                <p>Ras Al Khaimah is emerging as a compelling destination for real estate investment, featuring attractive
                    rental yields and property value appreciation with no property, income, or capital gains taxes.</p>
                <p>Unlike the bustling luxury markets of Dubai and Abu Dhabi, Ras Al Khaimah offers a quieter, more relaxed
                    lifestyle surrounded by stunning natural landscapes and a focus on eco-tourism. The confirmed opening of
                    a major casino is poised to significantly boost tourism and create new opportunities for investors,
                    setting it apart from the more established markets.</p>

                <p>Additionally, Ras Al Khaimah provides a more affordable entry point into the UAE real estate market,
                    while benefiting from stable governance and rapidly improving infrastructure, making it a prime option
                    for long-term growth.</p>
            </>
            : <>
                <h1>Почему стоит инвестировать в недвижимость в Рас Аль Хайме?</h1>
                <p>Рас Аль Хайма становится привлекательным направлением для инвестиций в недвижимость, предлагая выгодные
                    доходы от аренды и рост цен на объекты при отсутствии налогов на недвижимость, доход и прирост
                    капитала.</p>

                <p>В отличие от оживленных рынков роскошной недвижимости Дубая и Абу-Даби, Рас Аль Хайма предлагает более
                    спокойный и расслабляющий образ жизни на фоне живописных природных ландшафтов и акцента на экотуризм.
                    Ожидаемое открытие крупного казино обещает значительно увеличить туристический поток и создать новые
                    возможности для инвесторов, выделяя Рас Аль Хайму на фоне более известных рынков.</p>

                <p>Кроме того, Рас Аль Хайма предоставляет более доступный вариант входа на рынок недвижимости ОАЭ,
                    одновременно обеспечивая стабильное управление и быстро развивающуюся инфраструктуру, что делает этот
                    регион отличным выбором для долгосрочного роста.</p>
            </>
    )
}
export default RasAlKheimahPage