import {BrowserRouter, Route, Routes} from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import IndexPage from "./pages/IndexPage";
import DubaiPage from "./pages/DubaiPage";
import RasAlKheimahPage from "./pages/RasAlKheimahPage";
import AbuDhabiPage from "./pages/AbuDhabiPage";
import Faq from "./pages/Faq";
import Error404 from "./pages/Error404";

const Router = () => {
    return(
        <BrowserRouter>
            <Routes>
                <Route path={'/'} element={<MainLayout />}>
                    <Route index={true} element={<Faq />} />
                    <Route path={'/dubai'} element={<DubaiPage />} />
                    <Route path={'/ras-al-kheimah'} element={<RasAlKheimahPage />} />
                    <Route path={'/abu-dhabi'} element={<AbuDhabiPage />} />
                    <Route path={'/faq'} element={<Faq />} />
                    <Route path={'/policy'} element={<AbuDhabiPage />} />
                    <Route path="*" element={<Error404 />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default Router