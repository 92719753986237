import { createSlice } from '@reduxjs/toolkit'
import MobileDetect from 'mobile-detect'
import {iPadDetection, isAnyTablet} from "../device-detection";

const md = new MobileDetect(window.navigator.userAgent)
const isIPad = iPadDetection()
const userLang = navigator.language
let localeDetected = userLang.slice(0, 2)

if(localeDetected !== 'ru' && localeDetected !== 'en') {
    localeDetected = 'en'
}

if(localStorage.getItem('locale')) {
    localeDetected = localStorage.getItem('locale')
}

let device = null

if(!md.phone() && !md.mobile() && !isIPad) {
    device = 'desktop'
} else if (!!md.tablet() || isIPad) {
    device = 'tablet'
} else if (!!md.phone()) {
    device = 'phone'
} else {
    device = 'unknown'
}

// console.log( 'md.mobile', md.mobile() );          // 'Sony'
// console.log( 'md.phone', md.phone() );           // 'Sony'
// console.log( 'md.tablet', md.tablet() );          // null
// console.log( 'md.userAgent', md.userAgent() );       // 'Safari'
// console.log( 'md.os', md.os() );              // 'AndroidOS'
// console.log( 'md.is', md.is('iPhone') );      // false
// console.log( 'md.is', md.is('bot') );         // false
// console.log( 'md.version', md.version('Webkit') );         // 534.3
// console.log( 'md.versionStr', md.versionStr('Build') );       // '4.1.A.0.562'
// console.log( 'md.match', md.match('playstation|xbox') ); // false

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        locale: localeDetected,
        languages: [{en: 'English', ru: 'Русский'}],
        authenticated: localStorage.getItem('authenticated') ?? false,
        screen: {x:0, y:0},
        scroll: {y:0},
        isDesktop: !md.phone() && !md.mobile() && !isIPad,
        isTablet: !!md.tablet() || isIPad,
        isMobile: !!md.phone(),
        device: device
    },
    reducers: {
        setLocale: (state, {payload}) => {
            state.locale = payload
            localStorage.setItem('locale', payload)
        },
        setScreen: (state, {payload}) => {
            state.screen = {x: payload.x, y:payload.y}
        },
        setScroll: (state, {payload}) => {
            state.scroll = {y:payload.y}
        },
        setAuthenticated:  (state, {payload}) => {
            localStorage.setItem('authenticated', payload)
            state.authenticated = payload
        },
    }
})

export const {
    setLocale,
    setScreen,
    setScroll,
    setAuthenticated,
} = appSlice.actions
export default appSlice.reducer
