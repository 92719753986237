import './style.scss'
const Error404 = () => {
    return(
        <div className={'error-page'}>
            <div className="code">404</div>
            <div className="message">Not Found</div>
        </div>
    )
}

export default Error404