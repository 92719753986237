import './assets/pages-slide.scss'

const PageSlide = ({component}) => {
    return(
        <div className={'page-slide-container'}>
            {component}
        </div>
    )
}

export default PageSlide