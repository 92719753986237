import './style.scss'
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {closeForm} from "../../storage/FormReducer";

const SlidingContainer = ({children, close}) => {

    const d = useDispatch()

    const [panelMovable, setPanelMovable] = useState(false)
    let closeTimeout

    useEffect(() => {

        const openTimeout = setTimeout(() => {
            setPanelMovable(true)
        }, 100)

        return(() => {
            clearTimeout(openTimeout)
            clearTimeout(closeTimeout)
        })
    }, []);

    const closeHandler = () => {
        setPanelMovable(false)
        closeTimeout = setTimeout(() => {
            close(false)
        }, 300)
    }

    return(
        <div className={'sliding-container-component'}>
            <div className="btn btn-close" onClick={closeHandler}>
                <i className={'icon-close'}/>
            </div>
            <div className={`sliding-panel ${panelMovable ? 'active' : 'hidden'}`}>
                <div className="slider-body scrollable">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default SlidingContainer