import { createSlice } from '@reduxjs/toolkit'
export const indexPageTranslateSlice = createSlice({
    name: 'app',
    initialState: {
        en: {
            advantages: [
                {title: 'Strong IT Knowledge', description: 'We deeply understand the IT field and know what it takes to succeed. We help you find candidates that fit your needs perfectly'},
                {title: 'Soft-skills Evaluation', description: 'Our experts, trained in psychology, assess candidates\' soft skills. This ensures you don’t end up with someone unmotivated, burned out, or a poor team fit'},
                {title: 'Comprehensive Assessments', description: 'If needed, we’ll assess the candidate\'s technical skills, mindset, and English language abilities'},
                {title: 'Pre-screened Candidates', description: 'We only present candidates we’ve carefully screened, saving you valuable time'},
                {title: 'Clear Reports', description: 'Each candidate comes with a detailed, easy-to-understand report. Reference verification: We check references independently, ensuring the candidates are trustworthy'},
                {title: 'Reference Verification', description: 'We check references independently, ensuring the candidates are trustworthy'},
            ],
            positions: [
                {title: 'System Administrator', icon: 'icon-settings', description: 'Manages and maintains computer systems and networks'},
                {title: 'Network Engineer', icon: 'icon-network', description: 'Engaged in the design, installation and management of computer networks'},
                {title: 'Software Developer', icon: 'icon-code', description: 'Creates programs and applications for various platforms'},
                {title: 'Web Developer', icon: 'icon-web-svgrepo-com', description: 'Specializes in building websites and web applications'},
                {title: 'Information Security Engineer', icon: 'icon-security', description: 'Protects data and information systems from cyber threats'},
                {title: 'Data Analyst', icon: 'icon-diagram', description: 'Examines data to identify trends and make predictions based on the data'},
                {title: 'Database Administrator', icon: 'icon-database', description: 'Manages and maintains databases'},
                {title: 'DevOps Engineer', icon: 'icon-devops', description: 'Combines development and operations to accelerate the software development and delivery process'},
                {title: 'IT Architect', icon: 'icon-tools', description: 'Designs and builds information systems architecture'},
                {title: 'IT Consultant', icon: 'icon-consultant', description: 'Provides advice and guidance on information technology issues'},
                {title: 'Technical Writer', icon: 'icon-roll', description: 'Creates technical documentation such as user manuals and technical specifications'},
                {title: 'Software Tester', icon: 'icon-check-bold', description: 'Tests software for compliance with requirements and identifies errors'},
                {title: 'Systems Analyst', icon: 'icon-bricks-man', description: 'Studies business processes and proposes technological solutions for their automation'},
                {title: 'Big Data Engineer', icon: 'icon-molecula', description: 'Develops and maintains systems for processing and analyzing large amounts of data'},

            ]
        },
        ru: {
            advantages: [
                {title: 'Уверенные знания в области IT', description: 'Мы глубоко понимаем сферу IT и знаем, что нужно для достижения успеха. Мы поможем вам найти кандидатов, которые идеально соответствуют вашим потребностям'},
                {title: 'Оценка Soft-skills', description: 'Наши эксперты, прошедшие обучение психологии, оценивают Soft-skills кандидатов. Это гарантирует, что в итоге вы не получите немотивированного, выгоревшего или плохо вписывающегося в коллектив человека'},
                {title: 'Комплексные оценки', description: 'При необходимости мы оценим технические навыки кандидата, его мышление и знание английского языка'},
                {title: 'Предварительно отобранные кандидаты', description: 'Мы представляем только тщательно отобранных кандидатов, экономя ваше драгоценное время'},
                {title: 'Прозрачные отчеты', description: 'По каждому кандидату формируется подробный и понятный отчет - эеспертное заключение'},
                {title: 'Верификация рекомендаций', description: 'Мы самостоятельно проверяем рекомендации, гарантируя, что кандидаты заслуживают доверия'},
            ],
            positions: [
                {title: 'Сетевой инженер', icon: 'icon-network', description: 'Занимается проектированием, установкой и управлением компьютерными сетями'},
                {title: 'Разработчик ПО', icon: 'icon-code', description: 'Создает программы и приложения для различных платформ'},
                {title: 'Веб-разработчик', icon: 'icon-web-svgrepo-com', description: 'Специализируется на создании веб-сайтов и веб-приложений'},
                {title: 'Инженер по информационной безопасности', icon: 'icon-security', description: 'Защита данных и информационных систем от киберугроз'},
                {title: 'Аналитик данных', icon: 'icon-diagram', description: 'Изучает данные для выявления тенденций и делает прогнозы на их основе'},
                {title: 'Администратор баз данных', icon: 'icon-database', description: 'Управление и ведение баз данных'},
                {title: 'Инженер DevOps', icon: 'icon-devops', description: 'Объединяет разработку и эксплуатацию для ускорения процесса разработки и поставки программного обеспечения'},
                {title: 'IT-архитектор', icon: 'icon-tools', description: 'Проектирование и создание архитектуры информационных систем'},
                {title: 'IT-консультант', icon: 'icon-consultant', description: 'Предоставляет консультации и рекомендации по вопросам информационных технологий'},
                {title: 'Технический писатель', icon: 'icon-roll', description: 'Создает техническую документацию, такую как руководства пользователя и технические спецификации'},
                {title: 'Тестировщик', icon: 'icon-check-bold', description: 'Тестирует программное обеспечение на соответствие требованиям и выявляет ошибки'},
                {title: 'Системный аналитик', icon: 'icon-bricks-man', description: 'Изучает бизнес-процессы и предлагает технологические решения для их автоматизации'},
                {title: 'Инженер по большим данным', icon: 'icon-molecula', description: 'Разрабатывает и обслуживает системы для обработки и анализа больших объемов данных'},

            ]
        }

    },
    reducers: {
        setContent: (state, {payload}) => {
            state.content = payload
        },

    }
})

export const {
    setContent
} = indexPageTranslateSlice.actions
export default indexPageTranslateSlice.reducer
