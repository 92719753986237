import {setLocale} from "../../storage/AppReducer";
import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import {store} from "../../storage";
import './style.scss'
import {resetForm} from "../../storage/FormReducer";
const LocaleSelector = () => {

    const {isMobile, screen, locale} = useSelector(store => store.app)
    const [show, setShow] = useState(false)
    const d = useDispatch()

    useEffect(() => {
        if (screen.x < 768) {
            import('./responsive.scss');
        }
    }, [isMobile, screen.x])

    const selectHandler = (value) => {
        d(setLocale(value))
        // d(resetForm())
        setShow(false)
    }

    return(
        <div className={'locale-selector-component'}>
            <div className={`locale-container ${show ? 'active' : ''}`}>
                <div className="locale-btn current-locale-btn" onClick={() => setShow(!show)}>
                    {show ? <i className={'icon-arrow-slider'} /> : locale}
                </div>
                {show &&
                    <div className="dropdown">
                        <div className={`locale-btn ${locale === 'en' ? 'current' : ''}`}
                        onClick={() => selectHandler('en')}
                        >
                            EN
                        </div>
                        <div className={`locale-btn ${locale === 'ru' ? 'current' : ''}`}
                             onClick={() => selectHandler('ru')}
                        >
                            RU
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}
export default LocaleSelector