
export function iPadDetection () {

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Проверка на стандартный iPad
    const isIpad = /iPad/.test(userAgent);
    // Проверка на iPad под управлением iPadOS
    const isIpadOS = /Macintosh/.test(userAgent) && 'ontouchstart' in window;
    return isIpad || isIpadOS;

}

export function isAnyTablet() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Проверка на iPad
    const isIpad = /iPad/.test(userAgent);

    // Проверка на iPad под управлением iPadOS
    const isIpadOS = /Macintosh/.test(userAgent) && 'ontouchstart' in window;

    // Проверка на Android-планшет
    const isAndroidTablet = /Android/.test(userAgent) && !/Mobile/.test(userAgent);

    // Проверка на другие планшеты
    const isOtherTablet = /Tablet|PlayBook/.test(userAgent);

    // Возвращаем true, если устройство является планшетом
    return isIpad || isIpadOS || isAndroidTablet || isOtherTablet;
}