import axios from "axios";

export const CoreHttp = axios.create({
    baseURL: 'https://processing.permanent-systems.com',
    // baseURL: 'https://localhost',
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
    withXSRFToken: true,
    withCredentials: true,
});
