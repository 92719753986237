import './style.scss'
import {useSelector} from "react-redux";
import {store} from "../../storage";
import {useEffect, useState} from "react";

const Faq = () => {

    const {locale} = useSelector(store => store.app)

    const arr= {
        en: [
                {
                    question: 'Can Foreigners Purchase Property in the UAE?',
                    answer: 'Yes, foreigners can acquire property in the UAE, but only in designated areas known as freehold zones, which grant full ownership rights to foreign buyers.',
                    active: false
                },
                {
                    question: 'What Documents Are Needed to Buy Property in the UAE?',
                    answer: '<ul>' +
                        '<li>Buyer\'s Passport</li>' +
                        '<li>Signed Sale and Purchase Agreement (SPA)</li>' +
                        '<li>Proof of income and credit history for mortgage financing</li>' +
                        '<li>Incorporation documents and registration certificates for companies</li>' +
                        '</ul>',
                    active: false
                },
                {
                    question: 'What Types of Ownership Are There, and How Do They Differ?',
                    answer: '<b>Freehold:</b> Full ownership available to foreigners in specific zones.<br />' +
                        '<b>Leasehold:</b> Long-term lease (up to 99 years) with ownership rights remaining with the landowner.',
                    active: false
                },
                {
                    question: 'How Does Property Registration Work, and What Are the Additional Costs?',
                    answer: '<ul>' +
                        '<li><b>When buying property in Dubai, there are several costs involved:</b><br />' +
                        '4% Registration Fee<br />' +
                        'Admin Fee (typically under 5,000 AED)<br /><br />' +
                        '<li><b>When Buying Property From an Owner:</b>' +
                        '4% Registration Fee<br />' +
                        '2% Agent Commission<br />' +
                        '1-2% for Legal Services and Documentation</li></ul><br />' +
                        'Other potential expenses include insurance and utilities',
                    active: false
                },
                {
                    question: 'How Much Does Property Cost in the UAE?',
                    answer: '<b>Apartments in Dubai</b><br />' +
                        'Studios: From 650,000 AED (approx. $180,000) to 1,600,000 AED (approx. $440,000) in premium areas.<br />' +
                        '1-Bedroom Apartments: From 900,000 AED (approx. $250,000) to 2,000,000 AED (approx. $540,000).<br />' +
                        '2-Bedroom Apartments: From 1,300,000 AED (approx. $360,000) to 4,000,000 AED (approx. $1,080,000).<br />' +
                        '<br /><br />' +
                        '<b>Villas in Dubai</b><br />' +
                        '3-Bedroom Villas: From 2,200,000 AED (approx. $550,000) to 5,000,000 AED (approx. $1,360,000).<br />' +
                        '4-5 Bedroom Villas: From 3,000,000 AED (approx. $810,000) to 10,000,000 AED (approx. $2,700,000) and higher in premium areas.<br />' +
                        '<br /><br />' +
                        '<b>Luxury Real Estate (e.g., in Palm Jumeirah, Downtown Dubai, Dubai Marina)</b><br />' +
                        'Apartments and Penthouses: From 3,000,000 AED (approx. $810,000) to 20,000,000 AED (approx. $5,400,000).<br />' +
                        'Villas: From 10,000,000 AED (approx. $2,700,000) and up.',
                    active: false
                },
                {
                    question: 'Why is Buying in the UAE Safe?',
                    answer: 'The UAE boasts strict laws that protect buyers\' interests, including the mandatory use of escrow accounts when purchasing properties under construction. An escrow account ensures that the buyer\'s funds are transferred to the developer only upon the completion of specific construction phases.',
                    active: false
                },
                {
                    question: 'What is the Procedure for Buying Property in the UAE?',
                    answer: '<ol>' +
                        '<li>Choose the Property and Negotiate with the Developer</li>' +
                        '<li>Sign a Booking Agreement</li>' +
                        '<li>Sign the Sale and Purchase Agreement (SPA)</li>' +
                        '<li>Register the Transaction at the Dubai Land Department (DLD)</li>' +
                        '<li><b>When Purchasing Directly from a Developer:</b><br />' +
                        'Buyer pays a 4% registration fee to the DLD. This fee is mandatory for all real estate transactions in Dubai. After registration, the buyer receives "Oqood" — an electronic document confirming ownership rights (for properties under construction).</li>' +
                        '<li><b>Payments According to the Developer\'s Schedule:</b><br />' +
                        'Payments are made according to an approved plan. Some developers may offer flexible payment options, allowing part of the amount to be paid after completion.</li>' +
                        '<li><b>Completion of Construction and Handover:</b><br />' +
                        'Upon completion, the developer invites you for a walkthrough of the property. After inspection and final payment, the developer hands over the keys.</li>' +
                        '<li><b>Final Registration of Ownership:</b><br />' +
                        'After full payment and handover, your property is registered in the Title Deed at the DLD. This serves as the official document confirming your ownership.</li>' +
                        '</ol>',
                    active: false
                },
                {
                    question: 'What Are the Financing Options for Purchases?',
                    answer: 'Mortgages for non-residents are also available, but terms are generally less flexible. Banks may finance up to 50-75% of the property\'s value, with interest rates ranging from 3.5% to 6%.<br /><br />' +
                        '<b>Developer Payment Plans:</b> Some developers offer installment payment options, allowing you to make an initial deposit and pay the remaining amount over several years.<br />' +
                        '<b>Own Funds:</b> Purchasing property with cash is the simplest and quickest way to complete a transaction, as it avoids bank checks and interest rates.',
                    active: false
                },
                {
                    question: 'Are There Any Restrictions on Renting Out Property?',
                    answer: 'There are no restrictions on renting out property; however, owners must register the rental agreement in the Ejari system, which is a mandatory requirement for official rentals in Dubai. Short-term rentals are also allowed, but you must register the apartment with the Department of Tourism and Commercial Marketing (DTCM).',
                    active: false
                },
                {
                    question: 'How Can You Sell Property in the Future?',
                    answer: 'Sellers often turn to licensed real estate agents to expedite the selling process and attract more potential buyers. The agent lists your property on popular websites with full descriptions, photographs, and pricing. This can help attract more potential buyers. The agent organizes viewings for interested buyers and negotiates the price and terms.',
                    active: false
                },
                {
                    question: 'How Will Buying Property in Dubai Affect My Tax Obligations in My Country?',
                    answer: 'This depends on the tax laws of your country. In many countries, you are required to declare foreign assets and pay taxes on rental income or capital gains from sales. It\'s advisable to consult a tax professional to avoid double taxation.',
                    active: false
                },
                {
                    question: 'What Are the Requirements for Obtaining a Golden Visa in the UAE When Purchasing Property?',
                    answer: 'The golden visa is available to property buyers if the investment amounts to at least 2 million AED. It\'s important that the property is not mortgaged for more than 50% of its value.',
                    active: false
                },
                {
                    question: 'What Agreements Exist Between Countries to Protect Foreign Investors in the UAE?',
                    answer: 'The UAE has signed bilateral agreements with many countries to protect foreign investors, providing legal guarantees and property protection. There are also local laws designed to safeguard the interests of foreign investors, such as the Free Zones Law and the Consumer Protection Law.',
                    active: false
                },
            ],
        ru: [
            {
                question: 'Может ли иностранец приобрести недвижимость в Эмиратах?',
                answer: 'Да, иностранцы могут приобретать недвижимость в Эмиратах, но только в специально отведенных районах, называемых freehold zones. Эти зоны предоставляют иностранным покупателям полные права собственности на недвижимость.',
                active: false
            },
            {
                question: 'Какие документы нужны для покупки недвижимости в Эмиратах?',
                answer: '<ul>' +
                    '<li>Паспорт покупателя</li>' +
                    '<li>Подписанный договор купли-продажи (Sales and Purchase Agreement, SPA)</li>' +
                    '<li>В случае финансирования через ипотеку – подтверждение доходов и кредитную историю</li>' +
                    '<li>Для компаний – учредительные документы и свидетельства о регистрации</li>' +
                    '</ul>',
                active: false
            },
            {
                question: 'Для компаний – учредительные документы и свидетельства о регистрации',
                answer: '<b>Freehold</b> – это полная собственность на недвижимость, доступная иностранцам в определенных зонах.<br />' +
                    '<b>Leasehold</b> – это долгосрочная аренда недвижимости (до 99 лет), при этом права собственности остаются у землевладельца.',
                active: false
            },
            {
                question: 'При покупке на прямую от застройщика',
                answer: '<ul>' +
                    '<li><b>Покупка недвижимости в Дубае влечет за собой ряд расходов:</b><br />' +
                    '4% регистрационный сбор<br />' +
                    'Admin fee (below 5.000 AED)<br /><br />' +
                    '<li><b>При покупке недвижимости от собственника:</b></li>' +
                    '4% регистрационный сбор<br />' +
                    '2% агентское вознаграждение<br />' +
                    '1-2% за юридические услуги и оформление</li></ul>' +
                    '<br />Другие возможные расходы включают страхование и коммунальные услуги.</li>',
                active: false
            },
            {
                question: 'Сколько стоит недвижимость в Эмиратах?',
                answer: '<b>Апартаменты в Дубае</b><br />' +
                    'Студии: от 650,000 дирхамов (около 180.000 долларов США) до 1,600,000 дирхамов (около 440,000 долларов США) в элитных районах.<br />' +
                    '1-комнатные апартаменты: от 900,000 дирхамов (около 250,000 долларов США) до 2,000,000 дирхамов (около 540,000 долларов США).<br />' +
                    '2-комнатные апартаменты: от 1,300,000 дирхамов (около 360,000 долларов США) до 4,000,000 дирхамов (около 1,080,000 долларов США).<br />' +
                    '<br /><br />' +
                    '<b>Виллы в Дубае</b><br />' +
                    '3 спальни: от 2,200,000 дирхамов (около 550,000 долларов США) до 5,000,000 дирхамов (около 1,360,000 долларов США).<br />' +
                    '4-5 спален: от 3,000,000 дирхамов (около 810,000 долларов США) до 10,000,000 дирхамов (около 2,700,000 долларов США) и выше в элитных районах.<br />' +
                    '<br /><br />' +
                    '<b>Элитная недвижимость (например, в районах Palm Jumeirah, Downtown Dubai, Dubai Marina)</b><br />' +
                    'Апартаменты и пентхаусы: от 3,000,000 дирхамов (около 810,000 долларов США) до 20,000,000 дирхамов (около 5,400,000 долларов США).<br />' +
                    'Виллы: от 10,000,000 дирхамов (около 2,700,000 долларов США) и выше.',
                active: false
            },
            {
                question: 'Почему покупать в Эмиратах безопасно?',
                answer: 'ОАЭ имеет строгое законодательство, которое защищает интересы покупателей, включая обязательное использование эскроу-счетов при покупке объектов на стадии строительства. Эскроу-счет гарантирует, что средства покупателя будут переведены девелоперу только по завершению определенных этапов строительства.',
                active: false
            },
            {
                question: 'Какова процедура покупки недвижимости в Эмиратах?',
                answer: '<ol>' +
                    '<li>Выбор объекта и переговоры с застройщиком</li>' +
                    '<li>Подписание договора бронирования (Booking Agreement)</li>' +
                    '<li>Подписание договора купли-продажи (SPA - Sale and Purchase Agreement)</li>' +
                    '<li>Регистрация сделки в Департаменте Земель Дубая (Dubai Land Department - DLD)</li>' +
                    '<li><b>При покупке на прямую от застройщика:</b><br />' +
                    'Покупатель оплачивает 4% регистрационный сбор, который переводится в DLD. Это обязательный сбор для всех сделок с недвижимостью в Дубае. После регистрации, покупатель получает Oqood — электронный документ, подтверждающий право на объект недвижимости (при покупке объекта на стадии строительства).</li>' +
                    '<li><b>Платежи по графику застройщика:</b><br />' +
                    'Оплата происходит по утверждённому плану. Как правило, это несколько этапов (например, 10% при подписании договора, еще 30% — по мере строительства, и оставшиеся 60% — при передаче объекта). У некоторых застройщиков есть гибкие условия оплаты, когда часть суммы можно выплатить после завершения строительства.</li>' +
                    '<li><b>Завершение строительства и передача объекта:</b><br />' +
                    'По завершению строительства застройщик приглашает вас на осмотр объекта. В этот момент проверяются все детали и соответствие строительным стандартам. После осмотра и финальной оплаты застройщик передает ключи.</li>' +
                    '<li><b>Окончательная регистрация собственности:</b><br />' +
                    'После полной оплаты и передачи объекта, ваша недвижимость регистрируется в Титульной книге (Title Deed) в DLD. Это официальный документ, подтверждающий ваше право собственности на недвижимость. При этом за передачу собственности может взиматься дополнительная комиссия.</li>' +
                    '<li><b>Дополнительные расходы:</b><br />' +
                    'Помимо цены недвижимости и регистрационного сбора, возможны расходы на обслуживание недвижимости (service charges), коммунальные платежи, подключение электроэнергии и воды.</li>' +
                    '<li><b>При покупке недвижимости от собственника:</b><br/>' +
                    'Выбор недвижимости и заключение предварительного соглашения (MOU);<br/>' +
                    'Внесение залога (обычно 10%);<br/>' +
                    'Подписание договора купли-продажи (SPA);<br/>' +
                    'Регистрация в Земельном департаменте;<br/>' +
                    'Получение свидетельства о собственности;</li>' +
                    '</ol>',
                active: false
            },
            {
                question: 'Какие формы финансирования покупки?',
                answer: 'Ипотека для нерезидентов также доступна, но условия обычно менее гибкие. Банки могут финансировать до 50-75% стоимости объекта, с процентными ставками в диапазоне 3,5%–6%.<br /><br />' +
                    '<b>Developer Payment Plans:</b> Некоторые застройщики предлагают рассрочку на оплату, где можно внести первоначальный взнос (обычно 10-20%) и выплачивать оставшуюся сумму в течение нескольких лет как до завершения строительства, так и после передачи объекта.<br />' +
                    '<b>Собственные средства:</b> Покупка недвижимости за наличные (без привлечения кредита) является самым простым и быстрым способом оформления сделки, так как в этом случае отсутствуют банковские проверки и процентные ставки.',
                active: false
            },
            {
                question: 'Есть ли ограничения по аренде недвижимости?',
                answer: 'Ограничений для сдачи недвижимости в аренду нет, однако собственники должны зарегистрировать договор аренды в системе Ejari, что является обязательным требованием для официальной аренды в Дубае.',
                active: false
            },
            {
                question: 'Как можно продать недвижимость в будущем?',
                answer: 'Чаще всего продавцы обращаются к лицензированным агентам по недвижимости, чтобы ускорить процесс продажи и привлечь больше потенциальных покупателей. Агент размещает вашу недвижимость на популярных сайтах с полным описанием, фотографиями и указанием стоимости. Это может привлечь больше потенциальных покупателей. Агент организует показы для потенциальных покупателей и ведет переговоры о цене и условиях.',
                active: false
            },
            {
                question: 'Как покупка недвижимости в Дубае повлияет на налоговые обязательства в моей стране?',
                answer: 'Это зависит от налогового законодательства вашей страны. Во многих странах необходимо декларировать иностранные активы и уплачивать налоги на доходы от аренды или прирост капитала при продаже. Стоит проконсультироваться с налоговым специалистом, чтобы избежать двойного налогообложения.',
                active: false
            },
            {
                question: 'Какие есть ограничения на получение золотой визы в Эмиратах при покупке недвижимости?',
                answer: 'Золотая виза доступна для покупателей недвижимости, если сумма вложений составляет не менее 2 миллионов дирхамов. Важно, чтобы недвижимость не была заложена в ипотеку более чем на 50% от ее стоимости.',
                active: false
            },
            {
                question: 'Какие существуют договоренности между странами о защите иностранных инвесторов в Эмиратах?',
                answer: 'ОАЭ подписали двусторонние соглашения с многими странами для защиты иностранных инвесторов, которые предоставляют юридические гарантии и защиту собственности. Существуют также местные законы, защищающие интересы иностранных инвесторов, такие как Закон о свободных зонах и Закон о защите прав потребителей.',
                active: false
            },
        ]
    }

    const [faq, setFaq] = useState(arr)
    const closeAll = () => {
        setFaq(faq[locale].filter(i => i.active = false))
    }

    const openItem = (key) => {
        setFaq({...faq, [locale]: faq[locale].map((i, k) => {
                    if (key === k) {
                        return {
                            answer: i.answer,
                            question: i.question,
                            active: true,
                        }
                    } else {
                        return {
                            answer: i.answer,
                            question: i.question,
                            active: false,
                        }
                    }
                }
            )
        })
    }

    return(
        <>
            {
                locale === 'en'
                    ? <h1>Frequently Asked Questions<br />from Potential Property Buyers in Dubai</h1>
                    : <h1>Часто задаваемые вопросы<br/>от потенциальных покупателей недвижимости в Дубае</h1>
            }

            <div className="faq-component">

            {
                    faq[locale].map((i, k) =>
                        <div className={`faq-group ${i.active ? 'active' : ''}`} key={k}>
                            <div className={`question`} onClick={() => openItem(k)}>
                                <div className="num">{k + 1}</div>
                                <div className="text">{i.question}</div>
                                <div className="icon"><i className={'icon-arrow-slider'}/></div>
                            </div>

                            <div className="answer">
                                <div className={'text'} dangerouslySetInnerHTML={{__html: i.answer}} />
                            </div>
                        </div>)
                }


            </div>
        </>
    )
}

export default Faq