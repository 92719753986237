import { configureStore } from '@reduxjs/toolkit'
import {appSlice} from "./AppReducer";
import {indexPageTranslateSlice} from "./IndexPageTranslateReducer";
import {formSlice} from "./FormReducer";

export const store = configureStore({
    reducer: {
        app: appSlice.reducer,
        content: indexPageTranslateSlice.reducer,
        form: formSlice.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})
