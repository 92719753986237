import {useSelector} from "react-redux";
import {store} from "../../storage";

const DubaiPage = () => {

    const {locale} = useSelector(store  => store.app)

    return(
        locale === 'en'
            ? <>
                <h1>Why Dubai?</h1>
                <p>Buying property in Dubai offers numerous advantages, including high rental yields and strong resale
                    potential. The city has no property taxes, no income tax, and no capital gains tax, making it an
                    attractive option for investors seeking tax-efficient real estate investments.</p>

                <p>The market is well-regulated, ensuring transparency and investor protection. Additionally, Dubai’s
                    strategic location, world-class infrastructure, and luxury lifestyle options further enhance its
                    appeal.</p>

                <p>Dubai is one of the safest cities globally, with low crime rates and a stable political environment.
                    Sheikh Mohammed bin Rashid Al Maktoum’s vision for the city includes initiatives like the “Golden Visa”
                    program and smart city developments, providing confidence and credibility for investors and ensuring
                    long-term growth.</p>
            </>
            : <>
                <h1>Почему стоит инвестировать в недвижимость в Дубае?</h1>
                <p>Приобретение недвижимости в Дубае предоставляет множество преимуществ, среди которых высокие доходы от
                    аренды и значительный потенциал для перепродажи. Этот город не взимает налоги на недвижимость, налоги на
                    доход и налоги на прирост капитала, что делает его исключительно привлекательным для инвесторов,
                    стремящихся к налоговым преимуществам в сфере недвижимости.</p>

                <p>Дубайский рынок недвижимости отличается высокими стандартами регулирования, что обеспечивает прозрачность
                    и защиту прав инвесторов. Уникальное стратегическое положение города, развитая инфраструктура мирового
                    класса и разнообразные возможности для роскошной жизни ещё больше увеличивают его привлекательность.</p>

                <p>Кроме того, Дубай считается одним из самых безопасных городов в мире с низким уровнем преступности и
                    стабильной политической обстановкой. Видение шейха Mohammed bin Rashid Al Maktoum включает инициативы,
                    такие как программа «Золотая виза» и развитие умных городов, что создает уверенность и доверие у
                    инвесторов, обеспечивая долгосрочный рост и развитие рынка.</p>
            </>
    )
}
export default DubaiPage