export function getScrollbarWidth() {
    const div = document.createElement('div')
    div.style.width = '100px'
    div.style.height = '100px'
    div.style.overflowY = 'scroll'
    div.style.position = 'absolute'
    div.style.top = '-9999px'
    document.body.appendChild(div);
    const scrollbarWidth = div.offsetWidth - div.clientWidth;
    document.body.removeChild(div);
    return scrollbarWidth;
}

export function textResize() {
    let text = document.getElementsByClassName('width-text');
    for (let i = 0; i < text.length; i++) {
        let string = text[i].innerHTML;
        string = string.replace(/<[^>]+>/g, ''); //
        text[i].style.fontSize = (text[i].offsetWidth / string.length * 1.4).toFixed() + "px";
        text[i].innerHTML = string.replace(/(.)/g, '<span style="min-width: ' + (text[i].offsetWidth / string.length / 1.4).toFixed() + 'px">$1</span>');
    }
}